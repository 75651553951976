import { useRoutes, Navigate, useNavigate } from "react-router-dom";
import {Layout} from "../view/layout";
import { Home } from "src/view/home/home.tsx";
import { WelcomePage } from "src/view/welcome/Welcome.tsx";
import React from "react";
import { Airdrop } from "src/view/bitcell/Airdrop.tsx";
import { BitCellHistory } from "src/view/bitcell/History.tsx";
import { ReleaseRule } from "src/view/airDrop/releaseRule.tsx";
import { AirDropList } from "src/view/airDrop/airDropList.tsx";
import { Pledge } from "src/view/pledge/index.tsx";
import { NotFoundPage } from "src/view/components/NotFound.tsx";

const RedirectToHome = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate('/welcome');
    }, [navigate]);

    return null;
};
const GetRouters = () => {
    return useRoutes([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: '/',
                    element: <RedirectToHome />,
                },
                {
                    path: '*',
                    element: <NotFoundPage />
                },
                {
                    path: 'home',
                    element: <Home/>
                },
                {
                    path: 'welcome',
                    element: <WelcomePage></WelcomePage>
                },
                {
                    path: 'bitcell-airdrop',
                    element: <Airdrop></Airdrop>
                },
                {
                    path: 'bitcell-claim',
                    element: <BitCellHistory></BitCellHistory>
                },
                {
                    path: 'releaseRule',
                    element: <ReleaseRule></ReleaseRule>
                },
                {
                    path: 'airDropList',
                    element: <AirDropList></AirDropList>
                },
                {
                    path: 'pledge',
                    element: <Pledge></Pledge>
                }
            ]
        }
    ])
}
export default GetRouters