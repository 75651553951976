import './stake.scss'
import { useEffect, useRef, useState, Ref, useImperativeHandle, forwardRef } from "react";
import { useTokenApprove, useTokenBalance } from "src/hook/useToken.ts";
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { formatEther, parseEther } from "viem";
import {formatDate, handlePermillage, truncateNumber} from "src/utils/utils.ts";
import { useLocation } from 'react-router-dom';
import {
    useMinStakingAmount, useStakingDepositRecord,
    useStakingDurations, useStakingPendings,
    useStakingRewardPerBlock, useStakingRewardRecord,
    useStakingTotalShare, useStakingUserAmount
} from "src/hook/useStake.ts";
import {
    BaseError,
    useAccount,
    useEstimateGas,
    useReadContracts,
    useWaitForTransactionReceipt,
    useWriteContract
} from "wagmi";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import { contractAbiMap, ContractAbiTypeEnum } from "src/enums/contractAbiEnum.ts";
import { useMessage } from "src/view/components/MessageContext.tsx";
const cellTokenAbi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.CELL_TOKEN) as string)
const stakingAbi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.STAKING) as string)
const cellToken: any = networkList[networkStatus].tokenContract
const contractAddress: any = networkList[networkStatus].stakingContract
import clock from 'img/pledge/clock-icon.png'
import activeBlock from 'img/pledge/active-block.png'
import normalBlock from 'img/pledge/time-normal.png'
import { ethers } from "ethers";
import { Slider } from "antd";
import type { SliderSingleProps } from 'antd';
interface stakeProps {
    successRefresh: () => void
}
export const Stake = forwardRef((props: stakeProps, ref) => {
    const { successRefresh } = props;
    const location = useLocation();
    const { address } = useGlobalState()
    const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value}D`;
    const sliderContainerRef = useRef<any>(null as any);
    const [balance, setBalance] = useState(0)
    const [amountValue, setAmountValue] = useState(10)
    // const dates = [30, 90, 180, 360]
    const dates = [2592000, 7776000, 15552000, 31104000]
    // const [stakeTime, setStakeTime] = useState(30)
    const [stakeTime, setStakeTime] = useState(2592000)
    const [weight, setWeight] = useState(0)
    const [weights, setWeights] = useState([0,0,0,0] as number[])
    const [totalShare, setTotalShare] = useState(0)
    const [blockReward, setBlockReward] = useState(0)
    const [userAmount, setUserAmount] = useState(0)
    const [rewards, setRewards] = useState(0)
    const [depositRecord, setDepositRecord] = useState([] as DepositRecord[])
    const [positionIds, setPositionIds] = useState([] as number[])
    const { showMessage, closeMessage } = useMessage()
    const { refetch: getBalanceContract } = useTokenBalance(address)
    const { refetch: getApproveContract } = useTokenApprove(address)
    const { refetch: getMinStakingAmount } = useMinStakingAmount()
    // const { refetch: getStakingDurations } = useStakingDurations(60*60*24*stakeTime)
    const { refetch: getStakingDurations } = useStakingDurations(stakeTime)
    const { data: weight1, isSuccess: isSuccess1 } = useStakingDurations(dates[0])
    const { data: weight2, isSuccess: isSuccess2 } = useStakingDurations(dates[1])
    const { data: weight3, isSuccess: isSuccess3 } = useStakingDurations(dates[2])
    const { data: weight4, isSuccess: isSuccess4 } = useStakingDurations(dates[3])
    const { refetch: getStakingTotalShare } = useStakingTotalShare()
    const { refetch: getRewardPerBlock } = useStakingRewardPerBlock()
    const { refetch: getUserAmount } = useStakingUserAmount(address)
    const { refetch: getStakingDepositRecord } = useStakingDepositRecord(address, 999999999, 999999999)
    useImperativeHandle(ref, () => ({
        refreshBalance: getBalance
    }));

    useEffect(() => {
        if (location.state?.fromAirdrop) {
            setTime(1)
        }
    }, [location]);
    const getBalance = () => {
        getBalanceContract().then((res: any) => {
            const bigNumber = formatEther(res.data);
            if (parseFloat(bigNumber) > 0.000001) {
                setBalance(truncateNumber(parseFloat(bigNumber)))
            } else {
                setBalance(0)
            }
        })
    }
    useEffect(() => {
        if (address) {
            getBalance()
        }
    }, [address]);
    const changeValue = (value: any) => {
        const amountValue = value.target.value;
        const regex = /^(1|[1-9]\d*)(\.\d{0,6})?$/; // 允许大于等于1的数字
        if (regex.test(amountValue)) {
            setAmountValue(amountValue);
        }
    }
    const maxValue = () => {
        if (balance >= 10) {
            setAmountValue(balance)
        }
    }
    const getDurations = () => {
        getStakingDurations().then((res: any) => {
            if (res.data) {
                setWeight(Number(res.data[1]) / 10000)
            }
        })
    }
    useEffect(() => {
        if (isSuccess1 && isSuccess2 && isSuccess3 && isSuccess4) {
            const weights = [weight1, weight2, weight3, weight4].map((weight: any) => Number(weight[1]) / 10000);
            setWeights(weights);
        }
    }, [isSuccess1,isSuccess2,isSuccess3,isSuccess4]);
    const getTotalShare = () => {
        getStakingTotalShare().then((res: any) => {
            if (res.data) {
                setTotalShare(parseFloat(formatEther(res.data)))
            }
        })
    }
    const getBlockReward = () => {
        getRewardPerBlock().then((res: any) => {
            if (res.data) {
                setBlockReward(parseFloat(formatEther(res.data)))
            }
        })
    }
    const getStakeUserAmount = () => {
        getUserAmount().then((res: any) => {
            if (res.isSuccess) {
                setUserAmount(parseFloat(formatEther(res.data)))
            }
        })
    }
    const getDepositRecord = () => {
        getStakingDepositRecord().then((res: any) => {
            if (res.isSuccess) {
                setDepositRecord(res.data)
            }
        })
    }
    const { refetch: getPending } = useStakingPendings(positionIds)
    const getPendings = () => {
        getPending().then((res: any) => {
            if (res.isSuccess) {
                const iface = new ethers.Interface(stakingAbi)
                const decode = res.data.map((item: string) => {
                    return iface.decodeFunctionResult('pending', item)
                })
                const bigNums = decode.map((item: any) => {
                    return item[0]
                })
                const total = bigNums.reduce((acc: bigint, item: bigint) => acc + item, 0n);
                setRewards(parseFloat(formatEther(total)))
            }
        })
    }
    useEffect(() => {
        if (depositRecord.length) {
            const ids = depositRecord.map((item) => {
                return Number(item.position_index)
            })
            setPositionIds(ids)
        }
    }, [depositRecord]);
    useEffect(() => {
        if (positionIds.length) {
            getPendings()
        }
    }, [positionIds]);
    useEffect(() => {
        // getDurations()
    }, [stakeTime]);
    useEffect(() => {
        getTotalShare()
        getBlockReward()
        getStakeUserAmount()
        getDepositRecord()
    }, []);
    const APRCompute = () => {
        return ((amountValue*weights[timeIndex]/((amountValue*weights[timeIndex])+totalShare)*(blockReward*28800))*365/amountValue*100)
    }
    const expectedReward = () => {
        // return amountValue*(APRCompute()/100)/360*stakeTime
        return amountValue * (truncateNumber(APRCompute()) / 100) / 360 * (stakeTime / 86400);
    }
    const getUnlockDate = () => {
        const currentDate = new Date();
        // const futureDate = new Date(currentDate.getTime() + stakeTime * 24 * 60 * 60 * 1000);
        const futureDate = new Date(currentDate.getTime() + stakeTime * 1000);
        return futureDate.getTime(); // 返回时间戳
    };
    // approval
    const approvalLoading = useRef(false)
    const { data: approvalHash, isPending: approvalPending, writeContractAsync: approvalWrite, isSuccess: approvalIsSuccess} = useWriteContract()
    const approval = () => {
        approvalWrite({
            address: cellToken,
            abi: cellTokenAbi,
            functionName: 'approve',
            args: [contractAddress, 115792089237316195423570985008687907853269984665640564039457584007913129639935n],
            gasPrice: networkStatus ? 1000001234n : 5000001234n,
            gas: approvalGasLimit
        })
            .catch((error) => {
                if (error) {
                    const shortMessage = (error as BaseError)?.shortMessage;
                    if (shortMessage !== 'User rejected the request.') {
                        closeMessage()
                        if (shortMessage?.includes(':')) {
                            setTimeout(() => {
                                showMessage(shortMessage?.split(':')[1].trim())
                            }, 100)
                        } else {
                            setTimeout(() => {
                                showMessage(shortMessage)
                            }, 100)
                        }
                    }
                }
                approvalLoading.current = false
                setGasParams(null)
                setGasLimit(0n)
                setApprovalGasParams(null)
                setApprovalGasLimit(0n)
            })
    }
    const { isSuccess: approvalAsyncSuccess} = useWaitForTransactionReceipt({
        hash: approvalHash
    })
    useEffect(() => {
        if (approvalPending) {
            showMessage('Approval...',0)
        } else if (!approvalIsSuccess) {
            approvalLoading.current = false
            setGasParams(null)
            setGasLimit(0n)
            setApprovalGasParams(null)
            setApprovalGasLimit(0n)
            closeMessage()
        }
    }, [approvalPending]);
    useEffect(() => {
        if (approvalAsyncSuccess) {
            closeMessage()
            stakingGasComputed()
        }
    }, [approvalAsyncSuccess]);
    // approval gas computed
    const approvalGasComputed = () => {
        const iface = new ethers.Interface(cellTokenAbi)
        const data: any = iface.encodeFunctionData('approve',[contractAddress, 115792089237316195423570985008687907853269984665640564039457584007913129639935n])
        setApprovalGasParams({
            data: data
        })
    }
    const [approvalGasParams, setApprovalGasParams] = useState<any | null>(null)
    const approvalEstimateGas = useEstimateGas({
        to: cellToken,
        data: approvalGasParams?.data
    })
    const [approvalGasLimit, setApprovalGasLimit] = useState<bigint>(0n)
    useEffect(() => {
        if (approvalGasLimit !== 0n) {
            approval()
        }
    }, [approvalGasLimit]);
    useEffect(() => {
        if (approvalGasParams !== null) {
            approvalEstimateGas.refetch().then((res: any) => {
                if (res.isSuccess) {
                    const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
                    setApprovalGasLimit(BigInt(newGas))
                }
            })
        }
    }, [approvalGasParams]);
    // staking
    const { data: stakeHash, isPending: stakeIsPending, writeContractAsync: stakeWriteContract, isSuccess: stakeIsSuccess} = useWriteContract()
    const [showTips, setShowTips] = useState(false)
    const account = useAccount()
    const staking = () => {
        const targetId = networkList[networkStatus].id
        if (targetId !== account?.chainId) {
            showMessage('Wrong Network')
            return
        }
        if (amountValue < 10) {
            showMessage('At least 10 CELA')
            return;
        }
        const amount = parseEther(amountValue.toString())
        const balanceAmount = parseEther(balance.toString())
        console.log(amount, balanceAmount)
        if (balanceAmount < amount) {
            showMessage('Insufficient Balance')
            setShowTips(true)
            approvalLoading.current = false
            return
        }
        if (!approvalLoading.current) {
            approvalLoading.current = true
            getApproveContract().then((res: any) => {
                if (res.isSuccess) {
                    const allowance = res.data
                    const amount = parseEther(amountValue.toString())
                    if (allowance >= amount) {
                        stakingGasComputed()
                    } else {
                        approvalGasComputed()
                    }
                }
            })
        }
    }
    const stakingContract = () => {
        if (approvalLoading.current) {
            const amount = parseEther(amountValue.toString())
            stakeWriteContract({
                address: contractAddress,
                abi: stakingAbi,
                functionName: 'deposit',
                // args: [amount, 86400*stakeTime]
                args: [amount, stakeTime],
                gasPrice: networkStatus ? 1000001234n : 5000001234n,
                gas: gasLimit
            })
                .catch((error) => {
                    if (error) {
                        const shortMessage = (error as BaseError)?.shortMessage;
                        if (shortMessage !== 'User rejected the request.') {
                            closeMessage()
                            if (shortMessage?.includes(':')) {
                                setTimeout(() => {
                                    showMessage(shortMessage?.split(':')[1].trim())
                                }, 100)
                            } else {
                                setTimeout(() => {
                                    showMessage(shortMessage)
                                }, 100)
                            }
                        }
                    }
                    approvalLoading.current = false
                    setGasParams(null)
                    setApprovalGasParams(null)
                    setApprovalGasLimit(0n)
                    setGasLimit(0n)
                })
        }
    }
    const { isSuccess: stakeAsyncIsSuccess,isFetched: stakeAsyncIsFetched} = useWaitForTransactionReceipt({
        hash: stakeHash
    })
    // gas computed
    const stakingGasComputed = () => {
        const iface = new ethers.Interface(stakingAbi)
        const amount = parseEther(amountValue.toString())
        const data: any = iface.encodeFunctionData('deposit', [amount, stakeTime])
        setGasParams({
            data: data
        })
    }
    const [gasParams, setGasParams] = useState<any | null>(null)
    const estimateGas = useEstimateGas({
        to: contractAddress,
        data: gasParams?.data
    })
    const [gasLimit, setGasLimit] = useState<bigint>(0n)
    useEffect(() => {
        if (gasLimit !== 0n) {
            stakingContract()
        }
    }, [gasLimit]);
    useEffect(() => {
        if (gasParams !== null) {
            estimateGas.refetch().then((res: any) => {
                if (res.isSuccess) {
                    const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
                    setGasLimit(BigInt(newGas))
                } else {
                    const shortMessage = (res.error as BaseError)?.shortMessage;
                    if (shortMessage !== 'User rejected the request.') {
                        closeMessage()
                        if (shortMessage?.includes(':')) {
                            setTimeout(() => {
                                showMessage(shortMessage?.split(':')[1].trim())
                            }, 100)
                        } else {
                            setTimeout(() => {
                                showMessage(shortMessage)
                            }, 100)
                        }
                    }
                    approvalLoading.current = false
                    setGasParams(null)
                    setApprovalGasParams(null)
                    setApprovalGasLimit(0n)
                    setGasLimit(0n)
                }
            })
        }
    }, [gasParams]);
    useEffect(() => {
        if (stakeIsPending) {
            setTimeout(() => {
                showMessage('Staking...',0)
            }, 200)
        } else if (!stakeIsSuccess) {
            approvalLoading.current = false
            setGasParams(null)
            setGasLimit(0n)
            setApprovalGasParams(null)
            setApprovalGasLimit(0n)
            closeMessage()
        }
    }, [stakeIsPending]);
    const stakingSuccess = () => {
        setTimeout(() => {
            successRefresh()
            getDepositRecord()
            getBalance()
            showMessage('Successfully Staking')
            setAmountValue(10)
            setGasParams(null)
            setGasLimit(0n)
            setApprovalGasParams(null)
            setApprovalGasLimit(0n)
        }, 200)
    }
    useEffect(() => {
        if (stakeAsyncIsSuccess) {
            stakingSuccess()
        }
    }, [stakeAsyncIsSuccess]);
    useEffect(() => {
        closeMessage()
        approvalLoading.current = false
        setGasParams(null)
        setGasLimit(0n)
        setApprovalGasParams(null)
        setApprovalGasLimit(0n)
    }, [stakeAsyncIsFetched]);

    // claim reward
    const { data: claimHash, isPending: claimIsPending, writeContractAsync: claimWriteContract, isSuccess: claimIsSuccess } = useWriteContract()
    const claimLoading = useRef(false)
    const { isSuccess: claimAsyncIsSuccess,isFetched: claimAsyncIsFetched} = useWaitForTransactionReceipt({
        hash: claimHash
    })
    useEffect(() => {
        if (claimIsPending) {
            setTimeout(() => {
                showMessage('Claim...',0)
            }, 200)
        } else if (!claimIsSuccess) {
            claimLoading.current = false
            closeMessage()
        }
    }, [claimIsPending]);
    const claimSuccess = () => {
        setTimeout(() => {
            getPendings()
            showMessage('Successfully Claim')
        }, 200)
    }
    useEffect(() => {
        if (claimAsyncIsSuccess) {
            claimSuccess()
        }
    }, [claimAsyncIsSuccess]);
    useEffect(() => {
        closeMessage()
        claimLoading.current = false
    }, [claimAsyncIsFetched]);
    const claimContract = () => {
        if (!claimLoading.current) {
            claimLoading.current = true
            const iface = new ethers.Interface(stakingAbi)
            const encode = positionIds.map((item) => {
                return iface.encodeFunctionData('claim', [item])
            })
            claimWriteContract({
                address: contractAddress,
                abi: stakingAbi,
                functionName: 'multicall',
                args: [encode]
            })
                .catch((error) => {
                    if (error) {
                        const shortMessage = (error as BaseError)?.shortMessage;
                        if (shortMessage !== 'User rejected the request.') {
                            closeMessage()
                            if (shortMessage?.includes(':')) {
                                setTimeout(() => {
                                    showMessage(shortMessage?.split(':')[1].trim())
                                }, 100)
                            } else {
                                setTimeout(() => {
                                    showMessage(shortMessage)
                                }, 100)
                            }
                        }
                    }
                    claimLoading.current = false
                })
        }
    }
    // reward Record
    const startId = useRef(999999999)
    const pageSize = 5
    const { refetch: getReward } = useStakingRewardRecord(address, startId.current, pageSize)
    const [rewardRecord, setRewardRecord] = useState([] as DepositRecord[])
    const pageLoading = useRef(false)
    const getRewardRecord = () => {
        if (!pageLoading.current) {
            pageLoading.current = true
            getReward().then((res: any) => {
                if (res.isSuccess) {
                    const lastItemId = res.data[res.data.length - 1].id;
                    startId.current = Number(lastItemId) - 1
                    if (!rewardRecord.some(record => Number(record.id) === lastItemId)) {
                        setRewardRecord(prevItems => [...prevItems, ...res.data]);
                    }
                    pageLoading.current = false
                } else {
                    pageLoading.current = false
                }
            })
        }
    }
    const dataContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (dataContainerRef.current && rewardRecord.length) {
            dataContainerRef.current.addEventListener('scroll', handlePage);
        }
    }, [rewardRecord.length]);
    const handlePage = () => {
        if (!dataContainerRef.current) return;
        const { scrollTop, scrollHeight, clientHeight } = dataContainerRef.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            getRewardRecord()
        }
    }
    // end
    const timeList = ['30D', '90D', '180D', '360D']
    const [timeIndex, setTimeIndex] = useState<number>(0)
    const infoData = [
        { label: 'Expected Reward', value: '10000' },
        { label: 'APR', value: '100%' },
        { label: 'Unlock Date', value: 'yy-mm-dd' },
    ]

    const setTime = (i:number) => {
        setTimeIndex(i)
        setStakeTime(dates[i])
    }

    const changeTime = (value: number) => {
        setTime(value)
    }
    return (
        <div className={'stake-wrap'}>
            <div className={'top'}>
                <div className={'title-wrap'}>
                    <div className={'title'}>Amount to Stake</div>
                </div>
                <div className={'value-wrap'}>
                    <div className={'value'}>{handlePermillage(balance)}</div>
                    <div onClick={maxValue} className={'cursor-pointer max-btn'}>Max</div>
                </div>
            </div>
            <div className={'input-wrap'}>
                <input value={amountValue} type={'number'} onChange={(e) => changeValue(e)} className={'input'} placeholder="At least 10 CELA"/>
                <div className={'label'}>CELA</div>
            </div>
            {
                amountValue > balance && showTips ?
                <div className={'warning'}>{'Insufficient balance'}</div>
                    : null
            }
            <div className={'time-arr'}>
                <div className={'title'}>Select Staking Term：</div>
                {/*<div className={'time-bg'}>*/}
                {/*    <div className={`active-bg`} style={{ width: `${(timeIndex + 1) * 25 - 2.5}%` }}></div>*/}
                {/*    <div className={'active-icon'} style={{ left: `${(timeIndex + 1) * 25 - 5.5}%` }}></div>*/}
                {/*    {*/}
                {/*        Array.from({ length: timeIndex }).map((_, i) => (*/}
                {/*            <img key={i} className={'active-block'} style={{left: `${(i + 1) * 25 - 5 + i * 0.4}%`}}*/}
                {/*                 src={activeBlock} alt=""/>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</div>*/}
                <div ref={sliderContainerRef} className={'slider-container'}>
                    <div className={'slider-box'}>
                        <Slider min={0} max={3} tooltip={{
                            formatter,
                            open: false,
                        }} className="custom-slider" defaultValue={timeIndex} value={timeIndex} onChange={changeTime}/>
                        <div className={'block-list'}>
                            {
                                Array.from({length: 4}).map((item, index) => (
                                    <img key={index} src={`${timeIndex > index ? activeBlock : normalBlock }`} className={`single-block`} alt=""/>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={'time-list'}>
                    {
                        timeList.map((item, i) => (
                            <div key={i} onClick={() => setTime(i)}
                                 className={`single-time ${i === timeIndex ? 'active-single-time' : 'normal-single-time'} `}>
                                <img src={clock} alt=""/>
                                <div className={'time-label'}>{item}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={'info-wrap'}>
                <div className={'single-row not-last-row'}>
                    <div className={'label'}>Rewards</div>
                    <div className={'value'}>{handlePermillage(truncateNumber(expectedReward(), 8, true))} CELA</div>
                </div>
                <div className={'single-row not-last-row'}>
                    <div className={'label'}>Staking APR</div>
                    <div className={'value'}>{handlePermillage(truncateNumber(APRCompute(), 8).toFixed(2))}% APR</div>
                </div>
                <div className={'single-row'}>
                <div className={'label'}>Unlock Date</div>
                    <div className={'value'}>{formatDate(getUnlockDate())}</div>
                </div>
            </div>
            <div className={'btn-wrap'}>
                <div onClick={staking} className={`show-flex-box-r show-flex-center cursor-pointer stake-btn ${!address ? 'btn-disabled' : '' } `}>Stake</div>
            </div>
        </div>
    )
})