import "./header.scss"
import Logo from "img/header/logo.webp"
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useGlobalState } from "src/hook/useGlobalState.ts";
import { getStr } from "src/utils/utils.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import { useAccount, useAccountEffect, useSignMessage } from "wagmi";
import { useLocation } from "react-router-dom";
import { useMessage } from "src/view/components/MessageContext.tsx";
import { User } from "src/api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
export const Header = function () {
    const navigate = useNavigate()
    const { open } = useWeb3Modal()
    const openWebSite = () => {
        navigate('/welcome')
    }
    const account = useAccount()
    const { pathname } = useLocation()
    const { setAddress, address } = useGlobalState()
    const { signMessage, data, isSuccess, isError } =useSignMessage()
    const { showMessage } = useMessage()
    const tabList = ['Airdrop', 'Stake']
    const [currentTab, setCurrentTab] = useState(0)

    useAccountEffect({
        onDisconnect() {
            const token = localStorage.getItem('token')
            if (token) {
                User.logout().then(() => {
                    localStorage.removeItem('token')
                    setAddress(null)
                    if (!pathname.includes('bitcell')) {
                        navigate('/welcome')
                    }
                })
            }
        }
    })
    useEffect(() => {
        if (account.address !== undefined) {
            if (!address) {
                const token = localStorage.getItem('token')
                if (token) {
                    setAddress(account.address)
                }
            } else {
                if (address !== account.address) {
                    const token = localStorage.getItem('token')
                    if (token) {
                        User.logout().then(() => {
                            localStorage.removeItem('token')
                            setAddress(null)
                            setAccountAddress('')
                        })
                    }
                }
            }
        }
    }, [account]);
    useEffect(() => {
        setTimeout(() => {
            if (pathname === '/pledge') {
                setCurrentTab(1)
            } else {
                setCurrentTab(0)
            }
        }, 0)
        window.scrollTo(0, 0);
    }, [pathname]);

    // login
    const [accountAddress, setAccountAddress] = useState('')

    const [messageTxt, setMessageTxt] = useState('')
    useEffect(() => {
        if (account.address !== undefined) {
            setAccountAddress(account.address)
        }
    }, [account]);
    useEffect(() => {
        if (!address) {
            setCurrentTab(0)
            setAccountAddress('')
        }
    }, [address]);
    const loading = useRef(false)
    const getMessage = (address: string) => {
        if (!loading.current) {
            loading.current = true
            User.getMessage(address).then((res: any) => {
                if (res.code === 200) {
                    signMessage({message: res.data})
                    setMessageTxt(res.data)
                }
            })
        }
    }
    useEffect(() => {
        if (isError) {
            loading.current = false
        }
    }, [isError]);
    const loginAccount = () => {
        const formData = {
            ethAddress: accountAddress,
            hash: data,
            txt: messageTxt
        }
        User.login(formData).then((res: any) => {
            if (res.code === 200) {
                localStorage.setItem('token', res.data)
                setAddress(accountAddress)
            } else {
                showMessage('Login timeout')
            }
        })
    }
    const changeTab = (index:number) => {
        setCurrentTab(index)
        if (index === 0) {
            navigate('/releaseRule')
        } else {
            navigate('/pledge')
        }
    }
    useEffect(() => {
        if (isSuccess) {
            loading.current = false
            loginAccount()
        }
    }, [isSuccess]);
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (accountAddress && !token) {
            getMessage(accountAddress)
        }
    }, [accountAddress]);
    return (
        <div className={"header-page"}>
            <div className={'show-flex-box-r align-center'}>
                <img onClick={openWebSite} className={"logo"} src={Logo} alt=""/>
                <div className={'show-flex-box-r tab-list'}>
                    {
                        tabList.map((item:string, index: number) => (
                            <div onClick={() => changeTab(index)} key={index} className={`single-tab ${index === currentTab ? 'active-tab' : '' } `}>{item}</div>
                        ))
                    }
                </div>
            </div>
            <div className={"right-container"}>
                <div onClick={() => open()} className={"cursor-pointer connect-button"}>
                    {address ? getStr(address,4,4) : 'Connect'}
                </div>
            </div>
        </div>
    )
}